











































































































import Vue from 'vue'
import {NavigationGuardNext, Route} from 'vue-router';
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import LogoUploader from "@/components/SygniLogoUploader.vue";
import SygniCard from "@/components/layout/SygniCard.vue";
import {
  emptyInvestmentClient,
  InvestmentClient,
} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {email, required, url} from "vuelidate/lib/validators";
import {phone} from '@/shared/validators';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {BusinessLink, ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {UploadFileDTO} from "@/modules/genprox/models/Shared";
import Utils from "@/modules/shared/utils/utils";
import {BSpinner} from "bootstrap-vue";
import EditProfile from '../EditProfile.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import { CheckboxOption, SelectOption } from '@/store/types';

Component.registerHooks(['validations', 'beforeRouteLeave'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, LogoUploader, SygniCard, BSpinner, EditProfile, SygniModal},
})
export default class EditGeneralData extends Vue {

  logo: File = {} as File;

  industries: Array<CheckboxOption> = [];

  focusArea: Array<CheckboxOption> = [];

  profileData: ProfileDataState = {} as ProfileDataState;

  isLoading: boolean =  false;

  showModal: boolean = false;

  nextRoute: NavigationGuardNext<Vue> = null;

  hasChanges: boolean = false;

  investmentData: InvestmentClient = {} as InvestmentClient;

  async beforeMount() {
    this.isLoading = true;
    this.$store.commit('investors/setNewInvestor', emptyInvestmentClient);
    this.profileData = this.$store.getters['profile/getProfileData'];
    let dictionaries = undefined;
    let businessLinks = undefined;

    if(this.dictionaries == undefined) {
      dictionaries = this.$store.dispatch('profile/getDictionaries');
    }
    if(this.businessLinks.length == 0) {
      businessLinks = this.$store.dispatch('profile/getBusinessLinks', true);
    }

    await Promise.all([businessLinks, dictionaries]);

    this.dictionaries.industry.forEach((el: any) => {
      this.industries.push({label: el.value, value: this.profileData.generalData.industry.includes(el.value)});
    });

    Object.values(this.dictionaries.focusArea).forEach((el: any) => {
      this.focusArea.push({label: el.label, value: this.profileData.generalData.focusArea.includes(el.value)});
    });

    this.isLoading = false;
  }

  showUpdateMessage(type: string, title: string, text: string) {
    this.isLoading = false;
    this.showModal = false;

    this.$notify({
      duration: 2500,
      type,
      title,
      text
    });
  }

  handleFieldChange(): void {
    this.hasChanges = true;
    this.$v.$reset();
  }

  async updateProfile(nextRoute: NavigationGuardNext<Vue>) {
    if(!this.hasChanges) return;

    this.isLoading = true;
    this.$v.$touch();

    if(this.industries.length > 0) {
      this.profileData.generalData.industry = [];
      this.industries.filter(el => el.value).forEach((el) => {
        this.profileData.generalData.industry.push(el.label);
      });
    }

    if(this.focusArea.length > 0) {
      this.profileData.generalData.focusArea = [];
      this.focusArea.filter(el => el.value).forEach((el) => {
        this.profileData.generalData.focusArea.push(el.label);
      });
    }

    if(!this.$v.$error) {
      const updatedBusinessLinks = this.getUpdatedBusinessLinks();
      try {
        if(updatedBusinessLinks.length) {
          await this.$store.dispatch('profile/updateBusinessLinks', updatedBusinessLinks);
        }

        await this.$store.dispatch('profile/updateProfileData', this.profileData);

        if(this.logo) {
          const payload: UploadFileDTO = {
            config: Utils.getUploadFileConfig(),
            file: this.logo
          }
          await this.$store.dispatch('profile/uploadPhoto', payload);
        } else {
          await this.$store.dispatch('profile/deletePhoto');
        }
        this.profileData = this.$store.getters['profile/getProfileData'];
        this.showUpdateMessage('success', 'Changes saved', 'Your profile has been updated.');
        this.hasChanges = false;

        if(nextRoute) nextRoute();

      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.showUpdateMessage('error', 'Error', errorMessage);
      }

      this.$forceUpdate();
    } else {
      this.showUpdateMessage('error', 'Validation error', 'Please check your details.');
    }
  }

  uploadLogo(e: File) {
    this.hasChanges = true;
    this.logo = e;
  }

  deleteLogo() {
    this.hasChanges = true;
    this.logo = null;
  }

  getUpdatedBusinessLinks() {
    const updatedBusinessLinks =  this.businessLinks.filter((el: BusinessLink, index: number) => {
      return !(el.visibleInUserProfile == this.initialBusinessLinks[index].visibleInUserProfile && el.managementTeamMember == this.initialBusinessLinks[index].managementTeamMember);
    });

    return updatedBusinessLinks.map((el: BusinessLink) => {
      return { id: el.id, visibleInUserProfile: el.visibleInUserProfile, managementTeamMember: el.managementTeamMember};
    })
  }

  changeRoute() {
    if(this.nextRoute) this.nextRoute();
  }

  async confirmUpdateProfile() {
    await this.updateProfile(this.nextRoute);
  }

  get businessLinks() {
    return this.$store.getters['profile/getBusinessLinks'];
  }

  get initialBusinessLinks() {
    return this.$store.getters['profile/getInitialBusinessLinks'];
  }

  get investmentPotentials(): SelectOption<string> {
    if(!this.dictionaries) return undefined;
    return this.dictionaries.investmentPotential;
  }

  get investmentKnowledge(): SelectOption<string> {
    if(!this.dictionaries) return undefined;
    return this.dictionaries.investmentKnowledge;
  }

  get titles(): SelectOption<string> {
    if(!this.dictionaries) return undefined;
    return this.dictionaries.title;
  }

  get dictionaries() {
    return this.$store.getters['profile/getDictionaries'];
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if(this.hasChanges) {
      this.showModal = true;
      this.nextRoute = next;
    } else {
      next();
    }
  }

  validations() {
    return {
      profileData: {
        generalData: {
          firstName: { required },
          surname: { required },
          phone: { phone },
          email: { required, email },
          facebook: { url },
          linkedIn: { url },
        }
      }
    }
  }
}
